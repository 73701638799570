import { render, staticRenderFns } from "./AnswerRecord.vue?vue&type=template&id=dd90bb28&scoped=true&"
import script from "./AnswerRecord.vue?vue&type=script&lang=js&"
export * from "./AnswerRecord.vue?vue&type=script&lang=js&"
import style0 from "./AnswerRecord.vue?vue&type=style&index=0&id=dd90bb28&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd90bb28",
  null
  
)

export default component.exports